.management-transfer-page
	background: #fff
	.top-buttons-left-content
		margin: 0 15px
	.ant-layout-header
		padding: 0
		height: auto
		.ant-row
			width: 100%
	.ant-layout-content
		padding: 0 15px
	.trash
		display: flex !important
	.top-buttons-right-content
		width: 350px
		.ant-input-affix-wrapper
			width: 100%

	.ant-layout-content
		margin-top: 10px

	.management-transfer-no-data
		text-align: center
		margin-top: 90px
	.date-picker
		background: #fff !important

	.account-accounting-select
		width: 150% !important

	.operation-management-transfer-content-table
		display: flex
		margin: 0 0 10px 0
		align-items: center
		justify-content: flex-end

	.section-separation
		width: 100%
		margin-bottom: 20px
		border-bottom: 1px solid #d9d9d9

	.gs-table
		#management-transfer-table
			.ant-table-tbody
				.ant-table-cell
					padding: 8px

			.flex-fields
				min-width: 100px

	.cost-center-select
		width: 100% !important

	.filters-management-transfer
		margin-bottom: 10px

	.center-cost-ledger-switch
		&.ant-switch-checked
			background-color: #40a9ff !important

		&:hover.ant-switch-checked
			background-color: #3399cc !important

.gs-table.management-transfer
	.ant-table-thead > tr > th
		min-width: 100px
		max-width: 150px
		overflow: hidden
		text-overflow: ellipsis
		white-space: nowrap

	.ant-table-tbody > tr > td
		min-width: 100px
		max-width: 150px
		overflow: hidden
		text-overflow: ellipsis
		white-space: nowrap

	.ant-table-cell .ant-form-item.editable-cell
		margin-bottom: 0 !important

	.editable-cell
		margin: 0

	.input-text-cell
		border: none
		width: -webkit-fill-available

	.text-cell
		display: block
		text-overflow: ellipsis
		overflow: hidden
		white-space: nowrap
		width: 140px
