
.gs-table
    #table-transfer-management
        tr:not(.add-detail-btn-row)
            td.frozen-column
                background: #BFE4FF
            td.realized-column
                background: rgba(46, 182, 104, 0.12)
                color: #2EB668
            td.out-of-period-column
                opacity: 0.8

    .ant-table-thead > tr > th
        min-width: 100px
        max-width: 150px
        overflow: hidden
        text-overflow: ellipsis
        white-space: nowrap

    .ant-table-tbody > tr > td
        min-width: 100px
        max-width: 150px
        overflow: hidden
        text-overflow: ellipsis
        white-space: nowrap

    .ant-table-cell .ant-form-item.editable-cell
        margin-bottom: 0 !important

    .editable-cell
        margin: 0

    .input-text-cell
        border: none
        width: -webkit-fill-available

    .text-cell
        display: block
        text-overflow: ellipsis
        overflow: hidden
        white-space: nowrap
        width: 140px
