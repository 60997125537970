.distribution-rule-page
	background: #fff
	.top-buttons-left-content
		margin: 0 15px
	.ant-layout-header
		padding: 0
		height: auto
		.multiple-search-content
			&>span
				flex-direction: row
				display: flex
				align-items: center
				height: 100%
		.header-title-content
			width: 100%
			&>.ant-row
				width: 100%
				justify-content: space-between
			.gs-top-buttons
				width: auto
	.ant-layout-content
		padding: 0 15px
	.trash
		display: flex !important

.rule-distribution-modal
	.gs-secondary-button
		margin: 0
	.attribute_parameterization-new-attribute-modal-formula-workarea-tag
		height: 34px
	.editable-cell-value-contract-modal,
	.editable-cell-value-wrap
		display: flex
		align-items: center
		padding: 0 10px
		justify-content: center
		button,p
			margin: 0
		svg
			opacity: 0

	.editable-cell-value-contract-modal:hover
		svg
			opacity: 1
	.attribute_parameterization-new-attribute-modal-formula-workarea
		display: flex
		gap: 10px
		align-items: start
		align-content: flex-start
	.attribute_parameterization-new-attribute-modal-rule
		&:hover
			cursor: grab
		&:active
			cursor: grabbing
			position: relative
	.replicate-scenario-month
		width: 100%
	#attribute_parameterization-new-rules-modal-rules-section
		padding: 0 !important
		.attribute_parameterization-new-attribute-modal-rules-workarea
			flex-direction: row
			flex-wrap: wrap
			min-height: 180px !important
			max-height: 180px !important

	.date-content
		gap: 17px
		display: flex
		flex-direction: column
	.ant-col
		width: -webkit-fill-available
		.checkbox-content
			justify-content: center
		.ant-row
			.ant-form-item
				height: auto
				margin: 0
				.ant-input-number
					width: 100%
	.ant-tabs-tab
		width: auto !important
	.ant-modal-body
		padding: 24px 16px !important
		gap: 15px
	.distribution-rule-form
		gap: 20px
		display: flex
		flex-direction: column
		margin-bottom: 15px
		.ant-row
			justify-content: end
.sortable-content
	z-index: 1001
	.attribute_parameterization-new-attribute-modal-rule
		display: flex
		justify-content: center
		align-items: center
		border: 1px solid #D7D7D7
		border-radius: 3px
		background: #FCFCFC
		height: 34px
		width: -moz-fit-content
		width: fit-content
		padding-left: 16px
		box-sizing: border-box
		gap: 11px
		.attribute_parameterization-new-attribute-modal-rule-label
			display: flex
			justify-content: center
			align-items: center
			padding: 1px 8px
			height: 18px
			background: #E8F2F9
			font-weight: 400
			color: #0065B3
			border-radius: 2px
.rule-distribution-drawer
	#attribute_parameterization-new-rules-modal-rules-section
		height: 100%
		.attribute_parameterization-new-attribute-modal-rules-workarea
			max-height: 100% !important
			height: 100%
