.apportionment-main-container
    display: flex
    align-items: start
    #apportionment-list-table
        padding: 0 20px
        .center-text
            text-align: center
            white-space: nowrap
        .transitory-icon-column
            padding: 0
        .ant-table-thead th
            padding: 5px
        .open-apportionment-info-btn
            color: #fff
            background: #0065B3
            border-radius: 50%
            width: 22px
            height: 22px
            cursor: pointer
            margin-bottom: -7px
        .operation-column
            text-align: center !important
    .apportionment-form-section-container
        margin: 16px 0 0 20px
        height: calc( 100vh - 180px )
        background: #FCFCFC
        #apportionment-form
            border: 1px solid #d7d7d7
            display: flex
            flex-direction: column
            align-items: start
            height: 100%
            position: relative
            .apportionment-form-content
                display: flex
                align-items: start
                overflow-y: auto
                overflow-x: hidden
                width: 100%
                height: 100%
                border-top: 1px solid #D7D7D7
                .origin-form-section
                    border-right: 1px solid #D7D7D7
                .origin-form-section,
                .destination-form-section
                    display: flex
                    flex-direction: column
                    width: 280px
                    transition: width 0.3s ease
                    padding: 10px
                    &.collapsed
                        width: 60px
                        height: 100%
                    p
                        width: fit-content
                    .flex-field-container
                        padding: 10px

                .destination-form-section
                    position: relative
            footer
                position: absolute
                bottom: 0
                right: 0
                padding: 10px
                height: auto
                background: #ffffff
                width: inherit
    .apportionments-list-section
        flex: 1
        position: relative
        display: flex
        flex-direction: column
        align-items: start
        justify-content: start
        height: calc(100vh - 180px)
        border: 1px solid #d7d7d7
        border-left: none
        margin: 16px 20px 0 0
        .apportionments-list-content
            position: relative
            overflow: auto
            display: flex
            flex-direction: column
            align-items: start
            justify-content: start
            height: calc(100vh - 180px)
            header
                padding: 8px 16px
            .apportionments-tables-container
                margin-bottom: 60px
                padding: 0 16px
                tbody td
                height: 40px
                padding: 10px
                .ant-table-wrapper
                    margin: 12px 0
                .table-total-cel
                    z-index: 100
                .invalidTotalAmount
                    background: #FFC5C5
            .apportionment-empty-list-content
                display: flex
                flex-direction: column
                align-items: center
                justify-content: center
                margin-top: -52px
                align-self: center
                flex: 1
                margin-top: -52px
                h2
                    margin: 20px 0 10px
        .transitory-account-drawer
            border-top: 1px solid #D7D7D7
            display: flex
            height: 420px
            width: 100%
            transition: all 0.5s ease
            padding: 16px
            opacity: 1
            position: absolute
            bottom: 0px
            z-index: 1000
            flex-direction: column
            background: #fff
            overflow: auto

            &.collapsed
                height: 0px
                opacity: 0
                display: none
            .gs-tag
                width: fit-content
                margin: 10px 0 20px
        footer
            background: #ffffff
            z-index: 100
            position: absolute
            bottom: 0
            right: 0
            display: flex
            align-items: center
            justify-content: end
            padding: 8px
            border-top: 1px solid #d7d7d7

.apportionment-filters-container
    display: flex
    align-items: center
    justify-content: space-between
    padding: 0 16px 24px
    width: 98%

.gs-carousel
    .slick-arrow.slick-next
        z-index: 2 !important
    .slick-arrow.slick-prev
        z-index: 2 !important
.non-editable-cell
    background-color: #F5f5f5
    pointer-events: none

.captcha-apportionment
    display: flex
    flex-direction: column
    align-items: center
    gap: 10px
    justify-content: center
    .ant-col-24
        width: 250px
        display: flex
        justify-content: center
        font-size: 17px
    .canvas-content
        width: 60% !important
    .input-content
        margin-left: auto
        margin-right: auto

.ant-picker-year-panel .ant-picker-cell-inner
    width: 3rem !important
