.ant-dropdown
    border: none !important
    box-shadow: none !important
    .menu-dropdown
        display: flex
        gap: 10px
        align-items: center
.budget-projection-page-container
    background: #f1f2f3
.budget-projection-tab-container
    background: #fff
    padding: 10px 24px
    .ant-tabs-nav-list
        width: 40%
    .budget-projection-sidebar-container
        width: 280px
        border: 1px solid #ccc
        .budget-projection-sidebar-space-content
            width: 100%
            .ant-space-item:first-child
                padding: 10px
                background: #fcfcfc
            .ant-space-item:nth-child(2)
                background: #fcfcfc
                padding: 10px 0
            .gs-secondary-button
                margin: auto
                width: 250px
                justify-content: center
            .ant-menu-submenu-title[aria-expanded=true]
                background: #efefef
                color: rgba(0, 0, 0, 0.85)
            .ant-menu-item-selected
                background: #E8F2F9

.budget-projection-tab-content
    display: flex
    width: 100%
    align-items: start
    justify-content: start
    li.ant-menu-item
        display: flex
        span.ant-menu-title-content
            order: 1
            display: flex
            gap: 12px
        button
            order: 2
            border: none
            box-shadow: none
            background-color: transparent
    .budget-projection-table-container
        display: flex
        flex: 1
        flex-direction: column
        align-items: start
        justify-content: flex-start
        padding: 0px 24px 24px

        .budget-projection-table-operations
            display: flex
            align-items: center
            justify-content: space-between
            width: 100%
            margin-bottom: 5px

            .budget-projection-table-operations-buttons
                background: #fff 
                border: none
                button
                    background: #fff 
                    border: none

.ant-picker-dropdown ant-picker-dropdown-placement-bottomLeft 
    .ant-picker-panel .ant-picker-cell .ant-picker-cell-inner, .ant-picker-panel-container .ant-picker-cell .ant-picker-cell-inner